import { api_main, kakao_api } from "./lib/api";
let code;

window.onload = () => {
  const redirectUri = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
  const url = new URL(window.location.href);
  const searchParams = url.searchParams;
  code = searchParams.get("code");

  const isMobile = /Mobi/i.test(window.navigator.userAgent);

  if (code != null) {
    const makeFormData = (params) => {
      const searchParams = new URLSearchParams();
      Object.keys(params).forEach((key) => {
        searchParams.append(key, params[key]);
      });

      return searchParams;
    };

    kakao_api(
      "post",
      "https://kauth.kakao.com/oauth/token",
      makeFormData({
        grant_type: "authorization_code",
        client_id: SNS_KAKAO_KEY,
        redirect_uri: redirectUri,
        code: code,
      }),
      (res) => {
        if (res.msg == "OK") {
          onCallApi("KAKAO", res.result.data.access_token);
        }
      }
    );
  } else {
    window.location.href = !isMobile
      ? `./web.html?code=${code}`
      : `./login.html?code=${code}`;
  }

  const onCallApi = (id, accessToken) => {
    api_main(
      "post",
      "v1/immigrations/authentications",
      {
        provider: id,
        accessToken: `Bearer ${accessToken}`,
      },
      (res) => {
        if (res.msg == "OK") {
          const result = res.result;

          localStorage.setItem(
            "user_access_token",
            result.headers.authorization
          );

          // 비회원(Guest)
          if (!result.data.authenticated) {
            window.location.href = "./sign_up_step1.html";
          }
          // 회원(User)
          else {
            window.location.href = !isMobile
              ? "./web.html"
              : "./game_list.html";
          }
        } else {
          alert(`[API ERROR] : ${res.result.response.data.message}`);
        }
      }
    );
  };
};
