import axios from "axios";
import CryptoJS from "crypto-js";

export async function api(method, url, data, cb) {
  let option = {
    method: method,
    url: `${API_ENDPOINT}/${url}`,
    headers: {
      Authorization: `${localStorage.getItem("user_access_token")}`,
      ContentType:
        url.indexOf("archives") > -1
          ? "multipart/form-data"
          : "application/json",
      Accept: "application/json",
    },
  };
  if (data) option.data = data;
  axios(option)
    .then((result) => {
      cb && cb({ result: result, msg: "OK" });
    })
    .catch((error) => {
      cb && cb({ result: error, msg: "ERROR" });
    });
}

export async function api_main(method, url, data, cb) {
  let option = {
    method: method,
    url: `${API_ENDPOINT_MAIN}/${url}`,
    headers: {
      ContentType: "application/json;charset=UTF-8",
      Accept: "application/json",
    },
  };

  if (url.indexOf("authentications") == -1) {
    option.headers["Authorization"] = `${localStorage.getItem(
      "user_access_token"
    )}`;
  } else if (
    url.indexOf("v1/immigrations/authentications/burningBeaver") > -1
  ) {
    option.headers["Authorization"] = `Bearer ${SNS_NAVER_APPLE_TOKEN_KEY}`;
  }
  if (data) option.data = data;
  axios(option)
    .then((result) => {
      cb && cb({ result: result, msg: "OK" });
    })
    .catch((error) => {
      cb && cb({ result: error, msg: "ERROR" });
    });
}

export function kakao_api(method, url, data, cb) {
  let option = {
    method: method,
    url: `${url}`,
    headers: {
      "content-type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  };

  if (data) option.data = data;
  axios(option)
    .then((result) => {
      cb && cb({ result: result, msg: "OK" });
    })
    .catch((error) => {
      cb && cb({ result: error, msg: "ERROR" });
    });
}

export function encrypt(data) {
  const key = CryptoJS.enc.Hex.parse(ENCRYPTION_KEY); // 256비트 암호화 키
  const iv = CryptoJS.enc.Hex.parse(ENCRYPTION_IV); // 초기화 벡터

  // 텍스트 암호화
  const encrypted = CryptoJS.AES.encrypt(data, key, { iv: iv });

  // 암호화된 결과를 Base64로 인코딩
  const encryptedBase64 = encrypted.toString();
  return encryptedBase64;
}

export function decrypt(encData) {
  const key = CryptoJS.enc.Hex.parse(ENCRYPTION_KEY); // 256비트 암호화 키
  const iv = CryptoJS.enc.Hex.parse(ENCRYPTION_IV); // 초기화 벡터

  // Base64로 인코딩된 암호화된 데이터를 디코드
  const ciphertext = CryptoJS.enc.Base64.parse(encData);

  // 텍스트 복호화
  const decrypted = CryptoJS.AES.decrypt({ ciphertext: ciphertext }, key, {
    iv: iv,
  });
  // 복호화된 결과를 문자열로 변환
  const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
  return decryptedText;
}
